import React from "react";
import Menu from "./Menu";
import { menuListAll } from "../menuListAll";

const MenuImageList = (props) => {
  const {index} = props;
  return (
    <div className="menuList-container">
      {menuListAll[index].map((menu) => (
        <div key={menu.name} className="menu">
          <Menu
            name={menu.name}
            price={menu.price}
            src={menu.src}
          />
        </div>
      ))}
    </div>
  );
};

export default MenuImageList;
