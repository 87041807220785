
const Title = (props)=>{
    const{ children } = props
    return(
        <div className="title-container">
            <div className="title">{children}</div>
        </div>

    );
};

export default Title;