import React from 'react'

const LineLink = () => {
  return (
    <div className='lineLink-container'>
        <a href="https://lin.ee/x5riugZ"><img 
        src={`${process.env.PUBLIC_URL}/images/Lineを開くボタン.png`} alt="鴨緑江LINEを開く"/></a>
    </div>
  )
}

export default LineLink