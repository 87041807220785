import React, { useContext } from 'react'
import { TotalContext, ResetFlagContext, OrderContext } from '../App'

const ResetButton = () => {

const totalValue = useContext(TotalContext);
const resetFlagValue = useContext(ResetFlagContext);
const orderValue = useContext(OrderContext);

const reset = () => {
    totalValue.setTotal(0);
    resetFlagValue.setResetFlag(true);
    orderValue.setOrder([]);
}

  return (
    <div className='reset-container'>
        <button className='btn' onClick={reset}>注文内容をリセット</button>
        </div>
  )
}

export default ResetButton;