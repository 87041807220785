import { useState, useContext, useEffect } from "react";
import { TotalContext, OrderContext, ResetFlagContext } from "../App";

const MenuImage = (props) => {
  const { name, price, src } = props;

  const totalValue = useContext(TotalContext);
  const orderValue = useContext(OrderContext);
  const resetFlagValue = useContext(ResetFlagContext);

  const [quantity, setQuantity] = useState(0);

  useEffect(() => {
    setQuantity(0);
    resetFlagValue.setResetFlag(false);

  }, [resetFlagValue.resetFlag]);

  //個数を増やす機能
  const pulus = (e) => {
    const tmp = e.currentTarget.name;
    const orderList = orderValue.order;
    setQuantity(quantity + 1);
    totalValue.setTotal(totalValue.total + price);

    if (orderList.find((item) => item.name === tmp)) {
      orderValue.setOrder(
        orderList.map((order) => {
          if (order.name === tmp) {
            return { name: tmp, orderQuantity: (order.orderQuantity += 1) };
          } else {
            return order;
          }
        })
      );
    } else {
      orderValue.setOrder([...orderList, { name: tmp, orderQuantity: 1 }]);
    }
  };

  //個数を減らす機能
  const minus = (e) => {
    const tmp = e.currentTarget.name;
    const orderList = orderValue.order;
    if (quantity > 0) {
      setQuantity(quantity - 1);
      totalValue.setTotal(totalValue.total - price);
      orderValue.setOrder(
        orderList.map((order) => {
          if (order.name === tmp) {
            if (order.orderQuantity > 1) {
              return { name: tmp, orderQuantity: (order.orderQuantity -= 1) };
            } else {
              return {orderQuantity: 0};
            }
          } else {
            return order;
          }
        })
      );
    }
  };

  return (
    <div className="menu-container">
      <div>
        <img src={src} alt={name} />
      </div>
      <div className="name">
        <div>{name}</div>
        <div>税込{price}円</div>
      </div>

      <div className="btn">
        <button name={name} className="btn-square-so-pop" onClick={pulus}>
          +
        </button>
        <div className="number">{quantity}個</div>
        <button name={name} className="btn-square-so-pop" onClick={minus}>
          −
        </button>
      </div>
    </div> //container
  );
};

export default MenuImage;
