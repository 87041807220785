import './CSS/App.css';
import { createContext, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css'
import './service/firebase'

import Title from './compornents/Title';
import MenuList from './compornents/MenuList';
import OrderList from './compornents/OrderList';
import Footer from './compornents/Footer';
import ResetButton from './compornents/ResetButton';
import LineLink from './compornents/LineLink';



export const TotalContext = createContext();//合計値
export const ResetFlagContext = createContext();//リセットボタン
export const OrderContext = createContext();//注文内容を入れる配列


function App() {

  //StateをContextした✖️3
  const [total, setTotal] = useState(0);
  const totalValue = {
    total,
    setTotal,
  }

  const [resetFlag, setResetFlag] = useState(false);
  const resetFlagValue = {
    resetFlag,
    setResetFlag,
  }

  const [order, setOrder] = useState([]);
  const orderValue = {
    order,
    setOrder,
  }



  return (
    <>
      <div className='container'>
        <div className='simulaterProvider'>
        <Title>鴨緑江注文Simulator</Title>
          <TotalContext.Provider value={totalValue}>
            <ResetFlagContext.Provider value={resetFlagValue}>
              <OrderContext.Provider value={orderValue}>

                <div className='simulater'>
                  <div className='menuList'>
                    <Tabs>
                      <TabList>
                        <Tab>おすすめ品</Tab>
                        <Tab>焼き物１</Tab>
                        <Tab>焼き物２</Tab>
                        <Tab>野菜</Tab>
                        <Tab>ご飯類</Tab>
                        <Tab>麺類・スープ</Tab>
                        <Tab>その他の料理</Tab>
                      </TabList>
                      <TabPanel>
                        <MenuList index={0} />
                      </TabPanel>
                      <TabPanel>
                        <MenuList index={1} />
                      </TabPanel>
                      <TabPanel>
                        <MenuList index={2} />
                      </TabPanel>
                      <TabPanel>
                        <MenuList index={3} />
                      </TabPanel>
                      <TabPanel>
                        <MenuList index={4} />
                      </TabPanel>
                      <TabPanel>
                        <MenuList index={5} />
                      </TabPanel>
                      <TabPanel>
                        <MenuList index={6}/>
                      </TabPanel>
                    </Tabs>
                    <Tabs>
                      <TabList>
                        <Tab>アルコール１</Tab>
                        <Tab>アルコール２</Tab>
                        <Tab>アルコール３</Tab>
                        <Tab>JINRO</Tab>
                        <Tab>ソフトドリンク</Tab>
                      </TabList>
                      <TabPanel>
                        <MenuList index={7}/>
                      </TabPanel>
                      <TabPanel>
                        <MenuList index={8}/>
                      </TabPanel>
                      <TabPanel>
                        <MenuList index={9}/>
                      </TabPanel>
                      <TabPanel>
                        <MenuList index={10}/>
                      </TabPanel>
                      <TabPanel>
                        <MenuList index={11}/>
                      </TabPanel>
                    </Tabs>
                  </div>{/* menuList */}
                  <div className='orderList'>
                    <OrderList />
                    <Footer>税込{total}円です</Footer>
                  </div>{/* oreder-container */}
                </div>{/* simulater */}

                <ResetButton />
                <LineLink />
              </OrderContext.Provider>
            </ResetFlagContext.Provider>
          </TotalContext.Provider>
        </div> {/* simulateProvider */}
      </div>{/* /container */}
    </>
  );
}

export default App;
