import React, { useContext } from "react";
import {OrderContext} from '../App';

const OrderList = () => {
  const orderValue=  useContext(OrderContext);
  

  return (
    <div className="orederList-container">
      <div className="title">
        注文内容
      </div>

      {orderValue.order.map((order, index) => (
        order.orderQuantity !== 0 &&
        <div key={index} className="order">
              <div>{order.name}</div>
              <div>　{order.orderQuantity}個</div>
        </div>
      ))}
    </div>

  );
};

export default OrderList;